import Bugsnag from '@bugsnag/js';
import BugsnagPerformance from '@bugsnag/browser-performance';

import Navigo from 'navigo';

import homeRoute from './Routes/home';
import serviceRoute from './Routes/services';
import aboutRoute from './Routes/about';
import blogRoute from './Routes/blog';
import contact from './Routes/contact';
import contactSuccess from './Routes/contact-success';
import proposalRoute from './Routes/proposal';

import NavigationMobileMenu from './Components/Navigation/NavigationMobileMenu';
import CurrentYear from './Components/Footer/CurrentYear';
import DisplayActiveLanguage from './Components/Language/DisplayActiveLanguage';
import LanguagePreferencePopup from './Components/Language/LanguagePreferencePopup';

Bugsnag.start({ apiKey: '90f0d7a66bbf622e9b90553ec03dcfe8' });
BugsnagPerformance.start({ apiKey: '90f0d7a66bbf622e9b90553ec03dcfe8' });

const router = new Navigo('/');

router.on('/', () => {
  homeRoute();
});
router.on('/en', () => {
  homeRoute();
});

router.on('/leistungen', () => {
  serviceRoute();
});
router.on('/en/leistungen', () => {
  serviceRoute();
});

router.on('/about', () => {
  aboutRoute();
});
router.on('/en/about', () => {
  aboutRoute();
});

router.on('/kontakt', () => {
  contact();
});
router.on('/en/kontakt', () => {
  contact();
});

router.on('/success', () => {
  contactSuccess();
});
router.on('/en/success', () => {
  contactSuccess();
});

router.on(/insights\/(.*)/, () => {
  blogRoute();
});

router.on(/proposals\/(.*)/, () => {
  proposalRoute();
});

router.notFound(() => true);

router.resolve();

CurrentYear();
DisplayActiveLanguage();
LanguagePreferencePopup();

const mobileMenuButton = document.querySelector('#mobile-nav-button');
if (typeof mobileMenuButton !== 'undefined' && mobileMenuButton != null) {
  NavigationMobileMenu();
}
