import InfiniteMarquee from 'infinite-marquee';

export default () => {
  const marquee = new InfiniteMarquee({
    el: document.querySelector('.marquee'),
    direction: 'right',
    duration: 20,
    css: false,
  });

  marquee; //eslint-disable-line
};
