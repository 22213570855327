import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const mm = gsap.matchMedia();

  // Set according to the height of the list items and the text element heights
  let scrollFactor = 0;

  mm.add('(min-width: 768px)', () => {
    scrollFactor = -5.75;
  });

  mm.add('(max-width: 767px)', () => {
    scrollFactor = -3.75;
  });

  const list = document.querySelector('.services-roller_list');

  const itemsToClone = list.querySelectorAll('.services-roller_list-item');

  const modulo = itemsToClone.length * 4;

  // Clone elements to create a seamless loop
  for (let i = 0; i < 5; i += 1) {
    const fragment = document.createDocumentFragment();

    itemsToClone.forEach((item) => {
      const clone = item.cloneNode(true);
      fragment.appendChild(clone);
    });

    list.appendChild(fragment);
  }

  const rollerBandTimeline = gsap.timeline({
    repeat: -1,
    repeatRefresh: true,
    repeatDelay: 2,
    onRepeat: () => {
      detectActiveListItem(); //eslint-disable-line

      if (rollerBandTimeline.iteration() % modulo === 0) {
        rollerBandTimeline.iteration(itemsToClone.length);
        rollerBandTimeline.invalidate();
      }
    },
  });

  // Check which list items index matches the current iteration and add the active class
  function detectActiveListItem() {
    const listItems = document.querySelectorAll('.services-roller_list-item');

    listItems.forEach((item, index) => {
      if (index === rollerBandTimeline.iteration()) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
  }

  rollerBandTimeline.iteration(itemsToClone.length);
  rollerBandTimeline.invalidate();

  rollerBandTimeline
    .addLabel('start')
    .to('.services-roller_list-item', {
      y() {
        // 16px base font size * scrollFactor * current iteration
        // ensures movement is always centered
        return scrollFactor * 16 * rollerBandTimeline.iteration();
      },
      ease: 'circ.inOut',
      duration: 1,
    })
    .addLabel('end');
};
