import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const section = document.querySelector('[big-text="section"]');

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      scrub: true,
      pin: true,
      start: 'top top',
      end: '+=1500',
      markers: false,
    },
  });

  tl.from('[big-text="left"]', { y: '100svh', duration: 2 }).from('[big-text="item"]', {
    y: '100svh',
    duration: 2,
    stagger: 2,
  });
};
