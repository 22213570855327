import { gsap } from 'gsap';
import HeroAnimation from '../Components/Proposal/HeroAnimation';
import QuoteAnimation from '../Components/Proposal/QuoteAnimation';
import GoalsAnimation from '../Components/Proposal/GoalsAnimation';
import PaymentAnimation from '../Components/Proposal/PaymentAnimation';
import TimelineAnimation from '../Components/Proposal/TimelineAnimation';
import BigTextAnimation from '../Components/Proposal/BigTextAnimation';
import MixAnimation from '../Components/Proposal/MixAnimation';

export default () => {
  const mm = gsap.matchMedia();

  mm.add('(min-width: 991px)', () => {
    HeroAnimation();
    QuoteAnimation();
    MixAnimation();
    GoalsAnimation();
    BigTextAnimation();
    TimelineAnimation();
    PaymentAnimation();
  });
};
