import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const section = document.querySelector('[timeline="section"]');
  const headline = document.querySelector('[timeline="headline"]');
  const subheadline = document.querySelector('[timeline="subheading"]');
  const wrapper = document.querySelector('[timeline="wrapper"]');

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      scrub: true,
      pin: true,
      start: 'top top',
      end: '+=2000',
      markers: false,
    },
  });

  tl.from(headline, { y: 100, opacity: 0 })
    .from(subheadline, { y: 100, opacity: 0 })
    .from(wrapper, { opacity: 0 })
    .to('[timeline="fill-line"]', { width: '100%', stagger: 0.5 })
    .from('[timeline="description"]', { opacity: 0, stagger: 0.5 });
};
