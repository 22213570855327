import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const section = document.querySelector('[goals="section"]');
  const headline = document.querySelector('[goals="headline"]');
  const goalItems = document.querySelectorAll('[goals="item"]');
  const banner = document.querySelector('[goals="banner"]');

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      scrub: false,
      start: 'top+=15% center',
      markers: false,
    },
  });

  tl.from(headline, { y: 100, opacity: 0 })
    .from(goalItems, { opacity: 0, stagger: 0.2 })
    .from(banner, { y: 100, opacity: 0 });
};
