import { createApp } from 'petite-vue';

export default () => {
  createApp({
    showPopup: false,
    language: window.navigator.userLanguage || window.navigator.language,
    germanISOArray: ['de', 'de-AT', 'de-BE', 'de-CH', 'de-DE', 'de-IT', 'de-LI', 'de-LU'],
    initUserLanguage() {
      if (localStorage.getItem('userLanguage')) {
        this.showPopup = false;
      } else if (this.germanISOArray.includes(this.language) === false) {
        this.showPopup = true;
      }
    },
    closePopup() {
      this.showPopup = false;
      localStorage.setItem('userLanguage', this.language);
    },
    switchToEnglish() {
      localStorage.setItem('userLanguage', this.language);
      document.querySelector('[lang-popup-link="en-US"]').click();
    },
  }).mount('#language-preference-popup');
};
