import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

export default () => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const mediaQueries = gsap.matchMedia();

  const offsetMargin = '20rem';
  let rightArrowMovement = '';
  let leftArrowMovement = '';

  mediaQueries.add('(max-width: 991px)', () => {
    rightArrowMovement = '-200%';
    leftArrowMovement = '125%';
  });

  mediaQueries.add('(min-width: 992px)', () => {
    rightArrowMovement = '-100%';
    leftArrowMovement = '100%';
  });

  gsap.to('.section_animated-arrow-hero > *', { opacity: 1, duration: 1 });

  const heroArrowTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: '.section_animated-arrow-hero',
      start: 'top top',
      end: 'bottom top',
      scrub: 2,
      pin: true,
      markers: false,
    },
  });

  heroArrowTimeline
    .to('.hero_animation-arrow.is-default-left', {
      left: leftArrowMovement,
      duration: 1,
    })
    .to(
      '.hero_animation-arrow.is-default-right',
      {
        right: rightArrowMovement,
        duration: 1,
      },
      '<' //eslint-disable-line
    )
    .from(
      '.hero_animation-arrow.is-animated',
      {
        left: `calc(-100% - ${offsetMargin})`,
        duration: 1,
      },
      '<' //eslint-disable-line
    )
    .to('.hero_animation-arrow.is-animated', {
      scale: 4,
      duration: 1,
    })
    .to(
      '#hero-animation-container-1',
      {
        opacity: 0,
        duration: 1,
        pointerEvents: 'none',
        ease: 'power4.inOut',
      },
      '>-1.75' //eslint-disable-line
    )
    .from(
      '#hero-animation-container-2',
      {
        opacity: 0,
        duration: 1,
        ease: 'power4.inOut',
      },
      '>-0.5' //eslint-disable-line
    )
    .addLabel('label')
    .to('.hero_animation-arrow.is-animated', {
      scale: 20,
      duration: 2,
      ease: 'power4.inOut',
    })
    .from('.nav_component', { backdropFilter: 'blur(0px)' }, '<');

  document.querySelector('#hero-animation-container-next-1').addEventListener('click', () => {
    gsap.to(window, {
      scrollTo: heroArrowTimeline.scrollTrigger.labelToScroll('label'),
    });
  });

  document.querySelector('#hero-animation-container-next-2').addEventListener('click', () => {
    gsap.to(window, { duration: 1, scrollTo: '#section-services-roller' });
  });
};
