import lottie from 'lottie-web/build/player/lottie_light';

export default () => {
  const navMobileMenuButton = document.querySelector('#nav-lottie-container');

  const lottieAnimation = lottie.loadAnimation({
    container: navMobileMenuButton, // the dom element that will contain the animation
    renderer: 'svg',
    name: 'menu-nav-lottie',
    loop: false,
    autoplay: false,
    path: 'https://cdn.assets-slicemedia.de/slicemedia-website/static-assets/menu-nav-lottie.json', // the path to the animation json
  });

  const navigationButtonID = 'mobile-nav-button';
  const webflowNavigationOpenClass = 'w--open';

  const checkIfMenuIsOpen = () => {
    const elemToObserve = document.getElementById(navigationButtonID);
    let prevClassState = elemToObserve.classList.contains(webflowNavigationOpenClass);
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          const currentClassState = mutation.target.classList.contains(webflowNavigationOpenClass);
          if (prevClassState !== currentClassState) {
            prevClassState = currentClassState;
            if (currentClassState) {
              lottieAnimation.setSpeed(100);
              lottieAnimation.playSegments([0, 30], true);
            } else {
              lottieAnimation.setSpeed(100);
              lottieAnimation.playSegments([30, 0], true);
            }
          }
        }
      });
    });
    observer.observe(elemToObserve, { attributes: true });
  };

  checkIfMenuIsOpen();
};
