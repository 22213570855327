import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const section = document.querySelector('[mix="section"]');
  const headline = document.querySelector('[mix="headline"]');
  const leftBall = document.querySelector('[mix="ball-left"]');
  const rightBall = document.querySelector('[mix="ball-right"]');

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      scrub: true,
      pin: true,
      start: 'top top',
      end: '+=2000',
      markers: false,
    },
  });

  tl.from(headline, { y: 100, opacity: 0 })
    .from(leftBall, { opacity: 0 })
    .from(rightBall, { opacity: 0 });
};
