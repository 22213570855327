import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const section = document.querySelector('[speech-quote="section"]');
  const container = document.querySelector('[speech-quote="container"]');
  const speechBubble = document.querySelector('[speech-quote="bubble"]');
  const founderLeft = document.querySelector('[speech-quote="founder-left"]');
  const founderRight = document.querySelector('[speech-quote="founder-right"]');

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      scrub: false,
      start: 'top+=15% center',
      markers: false,
    },
  });

  tl.to(container, { opacity: 1 })
    .from(speechBubble, { y: 100, opacity: 0 })
    .from(founderLeft, { x: -100, opacity: 0 })
    .from(founderRight, { x: 100, opacity: 0 });
};
