import Splide from '@splidejs/splide';
import '@splidejs/splide/css'; //eslint-disable-line

export default () => {
  const blogSlider = new Splide('.splide', {
    autoWidth: true,
    gap: '2rem',
    drag: 'free',
    pagination: false,
    arrows: false,
    omitEnd: false,
    focus: 'center',
    snap: true,
  }).mount();

  blogSlider; //eslint-disable-line

  const nextButton = document.querySelector('[blog-crosslink-slider="next-button"]');
  const prevButton = document.querySelector('[blog-crosslink-slider="prev-button"]');

  nextButton.addEventListener('click', () => {
    blogSlider.go('+1');
  });

  prevButton.addEventListener('click', () => {
    blogSlider.go('-1');
  });

  const handlePrevButtonVisibility = () => {
    if (blogSlider.index === 0) {
      prevButton.classList.add('is-disabled');
    } else {
      prevButton.classList.remove('is-disabled');
    }
  };

  const handleNextButtonVisibility = () => {
    if (blogSlider.index === blogSlider.length - 1) {
      nextButton.classList.add('is-disabled');
    } else {
      nextButton.classList.remove('is-disabled');
    }
  };

  blogSlider.on('move', () => {
    handlePrevButtonVisibility();
    handleNextButtonVisibility();
  });

  blogSlider.on('dragged', () => {
    handlePrevButtonVisibility();
    handleNextButtonVisibility();
  });

  handlePrevButtonVisibility();
  handleNextButtonVisibility();
};
