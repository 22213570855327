import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const section = document.querySelector('[payment="section"]');
  const headline = document.querySelector('[payment="headline"]');
  const paymentWrapper = document.querySelector('[payment="wrapper"]');
  const paymentLeft = document.querySelectorAll('[payment="left"]');
  const paymentRight = document.querySelectorAll('[payment="right"]');

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      scrub: false,
      start: 'top+=20% center',
      markers: false,
    },
  });

  tl.from(headline, { y: 100, opacity: 0 })
    .from(paymentWrapper, { opacity: 0 })
    .to(paymentLeft, { x: '-2.5rem', y: '-2.5rem' })
    .to(paymentRight, { x: '2.5rem', y: '2.5rem' })
    .from('[payment="number"]', { opacity: 0 })
    .from('[payment="text"]', { opacity: 0 });
};
