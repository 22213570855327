import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const mm = gsap.matchMedia();

  mm.add('(min-width: 992px)', () => {
    const cardsAnimationTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '#value-promise-card-3',
        markers: false,
        pin: '.rolling-cards_list',
        start: 'top center',
        end: 'bottom center',
        scrub: 1,
      },
    });

    cardsAnimationTimeline
      .to('#value-promise-card-1', {
        yPercent: -155,
        duration: 3,
      })
      .to(
        '#value-promise-headline',
        {
          yPercent: -155,
          duration: 1,
        },
        '<' //eslint-disable-line
      )
      .to(
        '#value-promise-card-2',
        {
          yPercent: -77.5,
          duration: 2,
        },
        '<1' //eslint-disable-line
      );
  });
};
