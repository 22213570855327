import gsap from 'gsap';
import ServiceScroller from '../Components/Animation/ServiceScroller';
import BlogRefSlider from '../Components/Slider/BlogRefSlider';
import CasesOverview from '../Components/Cases/CasesOverview';
import NavigationDarkColor from '../Components/Navigation/NavigationDarkColor';
import HeroArrowAnimation from '../Components/Animation/HeroArrowAnimation';
import LogoMarquee from '../Components/Animation/LogoMarquee';

export default () => {
  gsap.to('body', { duration: 0, overflow: 'auto', height: 'auto' });

  HeroArrowAnimation();
  ServiceScroller();
  BlogRefSlider();
  CasesOverview();
  NavigationDarkColor();
  LogoMarquee();
};
