import { gsap } from 'gsap';

export default () => {
  const faqTriggers = document.querySelectorAll('.faq_head');

  faqTriggers.forEach((trigger) => {
    const triggerReference = trigger.getAttribute('faq-head-ref');

    const triggerIcon = trigger.querySelector('.button.is-round-icon');

    const faqOpenTimeline = gsap.timeline({ paused: true, yoyo: true });

    let isOpen = false;

    faqOpenTimeline
      .from(`[faq-body-ref="${triggerReference}"]`, {
        height: '0',
        duration: 0.5,
        ease: 'circ.inOut',
      })
      .from(
        triggerIcon,
        {
          rotateZ: -90,
        },
        '<0' //eslint-disable-line
      );

    trigger.addEventListener('click', () => {
      if (!isOpen) {
        isOpen = true;
        faqOpenTimeline.play();
      } else {
        isOpen = false;
        faqOpenTimeline.reverse();
      }
    });
  });
};
