import gsap from 'gsap';

export default () => {
  const container = document.querySelector('[hero="container"]');
  const stackWordLeft = document.querySelector('[hero="stack-left"]');
  const stackWordRight = document.querySelector('[hero="stack-right"]');
  const headline = document.querySelector('[hero="heading"]');
  const paragraph = document.querySelector('[hero="paragraph"]');
  const nextButton = document.querySelector('[hero="next-button"]');

  const tl = gsap.timeline({});

  tl.to(container, { opacity: 1 })
    .from(stackWordLeft, { xPercent: -50, opacity: 0 })
    .from(stackWordRight, {
      xPercent: 50,
      opacity: 0,
    })
    .from(headline, { y: 100, opacity: 0 })
    .from(paragraph, { y: 100, opacity: 0 })
    .from(nextButton, { y: 100, opacity: 0 });
};
