import Isotope from 'isotope-layout';
import { gsap } from 'gsap';

export default () => {
  const mm = gsap.matchMedia();

  mm.add('(min-width: 992px)', () => {
    const specialBanner = document.querySelector('[cases-overview="special-banner"]');
    const casesGrid = document.querySelector('.cases-overview_wrapper ');

    casesGrid.appendChild(specialBanner);

    const casesGridItem = new Isotope(casesGrid, {
      itemSelector: '.cases-overview_w-dyn-item',
      transitionDuration: 0,
    });

    setTimeout(() => {
      casesGridItem.reloadItems();
    }, 1000);
  });
};
