import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const mm = gsap.matchMedia();

  const toggleNavigationColor = (isDark) => {
    const lightColor = 'white';
    const darkColor = '#171717';

    gsap.defaults({ ease: 'circ.inOut', duration: 0.5 });

    if (isDark) {
      mm.add('(min-width: 992px)', () => {
        gsap.to('.nav_menu_link', { color: lightColor });
      });
      gsap.to('.nav_logo-svg', { color: lightColor });
      gsap.to('.nav_component .button', { background: lightColor, color: darkColor });
      gsap.to('.nav_component .button.is-icon.is-text', {
        background: 'transparent',
        color: lightColor,
      });
      gsap.to('.nav_button', { background: lightColor, color: darkColor });
      mm.add('(max-width: 991px)', () => {
        gsap.to('.button.is-icon.is-text ', { color: darkColor });
      });
    }

    if (!isDark) {
      gsap.to('.nav_menu_link', { color: darkColor });
      gsap.to('.nav_logo-svg', { color: darkColor });
      gsap.to('.nav_component .button', { background: darkColor, color: lightColor });
      gsap.to('.nav_component .button.is-icon.is-text', {
        background: 'transparent',
        color: darkColor,
      });
      gsap.to('.nav_button', { background: darkColor, color: lightColor });
    }
  };

  ScrollTrigger.create({
    trigger: '[navigation="dark-bg"]',
    start: 'top-=30 top',
    end: 'bottom-=50 top',
    markers: false,
    onToggle: (self) => {
      toggleNavigationColor(self.isActive);
    },
  });
};
