import { createApp } from 'petite-vue';

export default () => {
  createApp({
    activeGroup: 'projekt-typ',
    groupsArray: ['projekt-typ', 'launch-date', 'budget', 'message', 'contact-details'],
    displayWarning: false,
    timeline: '',
    sending: false, // control the sending state of the form
    checkGroupCheckboxMinimum() {
      const activeGroupDOM = document.getElementById(this.activeGroup);

      let checked = false;

      activeGroupDOM.querySelectorAll('input').forEach((input) => {
        if (input.checked) {
          checked = true;
        }
      });

      return checked;
    },
    checkGroupRequiredFields() {
      const activeGroupDOM = document.getElementById(this.activeGroup);
      const fields = activeGroupDOM.querySelectorAll('input, select, checkbox, textarea');

      let groupFilled = false;

      // use for loop to break out of the loop if the group is not filled
      // eslint-disable-next-line no-restricted-syntax
      for (const input of fields) {
        if (!input.checkValidity()) {
          groupFilled = false;
          input.reportValidity();

          return false;
        }

        groupFilled = true;
      }

      return groupFilled;
    },
    nextGroup() {
      let checkCheckbox = true;
      const requiredFieldsChecked = this.checkGroupRequiredFields();

      if (this.activeGroup === 'projekt-typ') {
        checkCheckbox = this.checkGroupCheckboxMinimum();
      }

      if (checkCheckbox && requiredFieldsChecked) {
        const currentIndex = this.groupsArray.indexOf(this.activeGroup);
        this.activeGroup = this.groupsArray[currentIndex + 1];
        window.scrollTo(0, 0);
      }

      if (!checkCheckbox || !requiredFieldsChecked) {
        this.displayWarning = true;

        setTimeout(() => {
          this.displayWarning = false;
        }, 3000);
      }
    },
    prevGroup() {
      const currentIndex = this.groupsArray.indexOf(this.activeGroup);
      this.activeGroup = this.groupsArray[currentIndex - 1];
    },
    submitForm() {
      const requiredFieldsChecked = this.checkGroupRequiredFields();

      if (requiredFieldsChecked && window.hcaptcha.getResponse() !== '') {
        document.querySelector('.contact_form').submit();

        this.sending = true;
      }

      if (requiredFieldsChecked && window.hcaptcha.getResponse() === '') {
        window.hcaptcha.execute();

        const checkResponse = () => {
          if (window.hcaptcha.getResponse() !== '') {
            document.querySelector('.contact_form').submit();

            this.sending = true;

            clearInterval(repeater); // eslint-disable-line no-use-before-define
          }
        };

        const repeater = setInterval(checkResponse, 500);
      }

      if (!requiredFieldsChecked) {
        this.displayWarning = true;

        setTimeout(() => {
          this.displayWarning = false;
        }, 3000);
      }
    },
  }).mount();
};
